import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { ArrowRightIcon, ArrowLeftIcon, ShareIcon, EyeIcon, 
  MagnifyingGlassIcon, MapPinIcon, BookmarkIcon, 
  QuestionMarkCircleIcon, Bars3Icon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid"; // Solid version

import SplashScreen from './splashscreen';
import './App.css';
import 'tailwindcss/tailwind.css';
import { useMediaQuery } from 'react-responsive';
import categories from "./categories.json";



mapboxgl.accessToken = 'pk.eyJ1IjoicGFua2FqLWd5YW5zaGFrdGkiLCJhIjoiY2x3dnB5ZDJnMG1sbDJycXptYnhiZnowdSJ9.5dGUs5xxc1GeXmn3cDfEYg';

const initialViewState = {
  center: [ 37.38683417241515, 55.8225372418895], // CROCUS Expo
  // zoom: 18,
  // pitch: 60,
  // bearing: 123.2,
  zoom: 17.25,
  zoom_max: 25.0,
  zoom_max_desktop: 25.0,
  zoom_fitbount: 19.0,
  zoom_fitbount_desktop: 18.7,
  zoom_min: 10.0,
  zoom_drawing: 21.0,
  bounds: [],
  active_poi_padding: 20.0,
  active_zone_padding: 20.0,
  pitch: 30.0,
  pitch_max: 60.0,
  pitch_min: 0.0,
  bearing: 9.0,
};


const App = () => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [booths, setBooths] = useState([]);
  const [passages, setPassages] = useState([]);
  const [filteredBooths, setFilteredBooths] = useState([]);
  const [selectedBooth, setSelectedBooth] = useState(null);
  const [hoveredBoothId, setHoveredBoothId] = useState(null); // For hover effect
  const [bookmarkedBooths, setBookmarkedBooths] = useState([]);
  const markers = useRef([]);
  const [collapsed, setCollapsed] = useState(true);
  const [activeItem, setActiveItem] = useState('Search');
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [selectedSectors, setSelectSectors] = useState(false);
  const [activeTask, setActiveTask] = useState('View All');
  const [visitedBooths, setVisitedBooths] = useState([]);
  const [isCategorySelectorVisible, setIsCategorySelectorVisible] = useState(false);
  const [isAmenitiesVisible, setIsAmenitiesVisible] = useState(false);
  const [isSupportTaskBarVisible, setIsSupportTaskBarVisible] = useState(false);


  const amenities = [
    'View all',
    'Male Washroom',
    'Female Washroom',
    'Male prayer hall',
    'Female prayer hall',
    'Lounges'
  ];
  
  const supportCategory = categories.find(
    (category) => category.name === "Support"
  );
  
  const supportCategories = supportCategory ? supportCategory.children.map(child => child.name) : [];
  
  
// To open the selector
const openCategorySelector = () => {
  setIsCategorySelectorVisible(true);
};

// To close the selector
const closeCategorySelector = () => {
  setIsCategorySelectorVisible(false);
};

  
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (item) => {
    setActiveItem(item);
  
    if (item === 'Bookmarks') {
      setBookmarksFilter(); // Highlight bookmarked booths when "Bookmarks" is selected
    } else if (item === 'Tasks') {
      setActiveTask('View All'); // Default task view
    }
  };
  
  const handleView = (company, map) => {
    setSelectedCompany(company);
    const coordinates = company.geometry.coordinates.slice();
    const { Name, Description, id } = company.properties;

    flyToCompany(map, coordinates, id);
  };

  const handleBack = () => {
    setSelectedCompany(null);
  };
  const setBookmarksFilter = () => {
    const filteredIds = bookmarkedBooths.map(booth => booth.id);
  
    if (map) {
      // Highlight bookmarked booths (extrude and change color)
      map.setPaintProperty('booths-layer', 'fill-extrusion-height', [
        'case',
        ['in', ['get', 'id'], ['literal', filteredIds]], 10, // Extrude bookmarked booths
        1 // Default height for non-bookmarked booths
      ]);
  
      map.setPaintProperty('booths-layer', 'fill-extrusion-color', [
        'case',
        ['in', ['get', 'id'], ['literal', filteredIds]], '#f48432', // Color for bookmarked booths
        '#cccccc' // Default color for non-bookmarked booths
      ]);
  
      // Fit the map to show all bookmarked booths
      const bookmarkedBoothCoordinates = bookmarkedBooths.flatMap(booth => booth.geometry.coordinates[0] || []);
      
      if (bookmarkedBoothCoordinates.length > 0) {
        const bounds = bookmarkedBoothCoordinates.reduce(
          (bounds, coord) => bounds.extend(coord), 
          new mapboxgl.LngLatBounds(bookmarkedBoothCoordinates[0][0], bookmarkedBoothCoordinates[0][1])
        );
        map.fitBounds(bounds, {   bearing: 9.0, pitch: 30.0,  zoom: 17.25, zoom_min: 10.0,   zoom_fitbount: 19.0, padding: 20, maxZoom: 25 });
      } else {
        console.error("No valid coordinates found for bookmarked booths.");
      }
    }
  };

  const convertToLngLat = (coordinates) => {
    return coordinates.flat().map(coord => [coord[0], coord[1]]);
  };
  
  


  const setFilter = (selectedCategories) => {
    if (selectedCategories.length === 0) {
      // Reset to view all booths
      setFilteredBooths(booths);  // Reset the filtered booths to all booths
      
      if (map) {
        // Reset the booth layer's paint properties to default
        map.setFilter('booths-layer-filtered', null); // Clear filters
        map.setFilter('booths-layer-nonfiltered', null); // Clear non-filtered layer
 //      Highlight filtered booths (extrude and change color)
        map.setPaintProperty('booths-layer', 'fill-extrusion-color', [
          'case',
          // Case when id % 15 == 0 (both % 3 and % 5)
          ['==', ['%', ['get', 'id'], 15], 0], '#FF6F61', // Color 1 (e.g., Red)
          // Case when id % 5 == 0
          ['==', ['%', ['get', 'id'], 5], 0], '#FFA07A', // Color 2 (e.g., Light Coral)
          // Case when id % 3 == 0
          ['==', ['%', ['get', 'id'], 3], 0], '#FFD700', // Color 3 (e.g., Gold)
          // Default color
          '#ADFF2F' // Color 4 (e.g., GreenYellow)
      ]);
      
  
      map.setPaintProperty('booths-layer', 'fill-extrusion-height', [
          'case',
          ['==', ['get', 'id'], selectedBooth], // Full height for selected booth
          4,
          ['==', ['get', 'id'], hoveredBoothId], // Half height for hovered booth
          2,
          0, // Default height
    ]);
        resetView(); // Optional: Reset the map view to the default view
      }
    } 
    else 
    {
      let filtered = [];
      let filteredBoothCoordinates = [];
      if( selectedCategories[0].type === 'Feature' ) // called from search
      {
        filtered  = selectedCategories;
        // Ensure all filtered booths have valid coordinates
        filteredBoothCoordinates = filtered.flatMap(booth => booth.geometry.coordinates[0] || []);
        // Convert the coordinates to the correct format
        const convertedCoordinates = convertToLngLat(filteredBoothCoordinates);     
        filteredBoothCoordinates = convertedCoordinates ; 
      }
      else // from select categories
      {
        filtered = booths.filter(booth => {
          const categories = Array.isArray(booth.properties.category)
            ? booth.properties.category
            : [booth.properties.category]; // Convert to array if it's not already
        
          return categories.some(category => selectedCategories.includes(category));
        });
        
        // Ensure all filtered booths have valid coordinates
        filteredBoothCoordinates = filtered.flatMap(booth => booth.geometry.coordinates[0] || []);
      }
      setFilteredBooths(filtered);
    
      const filteredIds = filtered.map(b => b.id);
  
      // Highlight filtered booths (extrude and change color)
      map.setPaintProperty('booths-layer', 'fill-extrusion-height', [
        'case',
        ['in', ['get', 'id'], ['literal', filteredIds]], 10, // Extrude filtered booths
        1 // Default height for non-filtered booths
      ]);
      
  
      map.setPaintProperty('booths-layer', 'fill-extrusion-color', [
        'case',
        ['in', ['get', 'id'], ['literal', filteredIds]], '#f48432', // Color for filtered booths
        '#cccccc' // Default color for non-filtered booths
      ]);
      
  
      // Check if there are valid coordinates before calling fitBounds
      if (filteredBoothCoordinates.length > 0) 
      { 
        let fc = [];
        if( Array.isArray(filteredBoothCoordinates[0][0]))
        {
          fc = convertToLngLat( filteredBoothCoordinates );
        }
        else
          fc = filteredBoothCoordinates;

        const bounds = fc.reduce(
          (bounds, coord) => bounds.extend(coord), 
          new mapboxgl.LngLatBounds(fc[0], fc[1])
        );
        map.fitBounds(bounds, {   bearing: 9.0, pitch: 30.0,  zoom: 17.25, zoom_min: 10.0,   zoom_fitbount: 19.0, padding: 20, maxZoom: 25 });
      } 
      else 
      {
        console.error("No valid coordinates found for filtered booths.");
      }
    }
  };
  
    
  const saveBookmarksToLocalStorage = (bookmarks) => {
    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
  };
;
  const toggleBookmark = (booth) => {
    setBookmarkedBooths((prevBookmarkedBooths) => {
      const updatedBookmarks = prevBookmarkedBooths.some((b) => b.id === booth.id)
        ? prevBookmarkedBooths.filter((b) => b.id !== booth.id)
        : [...prevBookmarkedBooths, booth];
      
      // Save the updated bookmarks to local storage
      saveBookmarksToLocalStorage(updatedBookmarks);
      return updatedBookmarks;
    });
  };

  const setActiveAmenity = (amenity) => {
    if (amenity === 'View all') {
      resetView(); // Reset map to show all amenities
    } else {
      // Add logic to filter map elements based on the selected amenity
    }
  };
  const setActiveSupport = (support) => {
    if (support === 'View all') {
      resetView(); // Reset map to show all amenities
    } else {
      // Add logic to filter map elements based on the selected amenity
    }
  };
  
const saveVisitedBoothsToLocalStorage = (visitedBooths) => {
  localStorage.setItem('visitedBooths', JSON.stringify(visitedBooths));
};
  const toggleVisited = (boothId) => {
    setVisitedBooths((prevVisitedBooths) => {
      const isVisited = prevVisitedBooths.includes(boothId);
      const updatedVisitedBooths = isVisited
        ? prevVisitedBooths.filter((id) => id !== boothId)
        : [...prevVisitedBooths, boothId];
  
      // Save the updated visited booths to local storage
      saveVisitedBoothsToLocalStorage(updatedVisitedBooths);
      return updatedVisitedBooths;
    });
  };

  useEffect(() => {
    // Simulate a loading time of 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3000ms = 3 seconds
  
    // Load bookmarks from localStorage
    const savedBookmarks = localStorage.getItem('bookmarks');
    if (savedBookmarks) {
      setBookmarkedBooths(JSON.parse(savedBookmarks));
    }
  
    // Load visited booths from localStorage
    const savedVisitedBooths = localStorage.getItem('visitedBooths');
    if (savedVisitedBooths) {
      setVisitedBooths(JSON.parse(savedVisitedBooths));
    }
  
    // Handle Escape key press to close popups
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        // Close all popups and reset views
        setSelectedCompany(null);
        setActiveItem('Search');
        setActiveTask('View All');
        //resetView();
      }
    };
  
    // Add the Escape key event listener
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the timer and event listener when the component unmounts
    return () => {
      clearTimeout(timer);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  

  useEffect(() => {
    if (map) {
      if( selectedBooth === undefined || selectedBooth === null )
        setSelectedBooth( 0 );
      if( hoveredBoothId === undefined || hoveredBoothId === null )
        setHoveredBoothId( 0 );
      map.setPaintProperty('booths-layer', 'fill-extrusion-height', [
        'case',
        ['==', ['get', 'id'], selectedBooth], // Full height for selected booth
        5,
        ['==', ['get', 'id'], hoveredBoothId], // Half height for hovered booth
        3,
        0 // Default height
      ]);
    }
  }, [hoveredBoothId, selectedBooth]);

  useEffect(() => {
      if (!loading && mapContainer.current) {  // Ensure mapContainer is available and loading is done

      const initializeMap = ({ setMap, mapContainer }) => {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/light-v11',
          attributionControl: false,
          language: 'en',
          touchZoomRotate: true,
          trackResize: true,
          center: initialViewState.center,
          zoom: initialViewState.zoom,
          bearing: initialViewState.bearing,
//          pitch: initialViewState.pitch,
          pitch: 30,
          pitch_max: 60,
          pitch_min: 0,
          zoom_drawing: 21,
          zoom_fitbount: 19,
          zoom_fitbount_desktop: 18.7,
          zoom_max: 25,
          zoom_max_desktop: 25,
          zoom_min: 10
        });
        map.attributionControl = false;
        map.language = "en";

        map.on('load', () => {
          // Add zoom and rotation controls to the map.
          map.addControl(new mapboxgl.NavigationControl({
            visualizePitch: true
          }), isDesktop ? 'bottom-right' : 'top-right');

          // Add custom reset view control
          const ResetControl = () => {
            const control = document.createElement('button');
            control.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-reset';
            control.type = 'button';
            control.title = 'Reset View';
            control.innerHTML = '⟳'; // Use an icon or text
            control.onclick = () => {
              map.flyTo({
                center: initialViewState.center,
                zoom: initialViewState.zoom,
                pitch: initialViewState.pitch,
                bearing: initialViewState.bearing,
                essential: true,
              });
            };
            return control;
          };
         
          class ResetViewControl {
            onAdd(map) {
              this.map = map;
              this.container = document.createElement('div');
              this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
              this.container.appendChild(ResetControl());
              return this.container;
            }

            onRemove() {
              this.container.parentNode.removeChild(this.container);
              this.map = undefined;
            }
          }

          map.addControl(new ResetViewControl(), 'bottom-right');

          // render layout
          fetch('./weldex-merged.geojson')
            .then(response => response.json())
            .then(data => {
              const stands = data.features.filter(feature => feature.properties.type != 'stand');
              const passages = data.features.filter(feature => feature.properties.type === 'passage');
              setBooths(stands);
              setPassages(passages);
              //setFilteredBooths(stands);

              map.addSource('booths', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: stands,
                },
              });

              map.addSource('passages', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: passages,
                },
              });

         
              map.addLayer({
                id: 'booths-layer',
                type: 'fill-extrusion',
                source: 'booths',
               paint: {
                  'text-color': '#000',
                  'fill-color' :   [
                     'case',
                    // Case when id % 15 == 0 (both % 3 and % 5)
                    ['==', ['%', ['get', 'id'], 15], 0], '#FF6F61', // Color 1 (e.g., Red)
                    // Case when id % 5 == 0
                    ['==', ['%', ['get', 'id'], 5], 0], '#FFA07A', // Color 2 (e.g., Light Coral)
                    // Case when id % 3 == 0
                    ['==', ['%', ['get', 'id'], 3], 0], '#FFD700', // Color 3 (e.g., Gold)
                    // Default color
                    '#ADFF2F' // Color 4 (e.g., GreenYellow)
                    ],
                  'fill-extrusion-color':   [
                   'case',
                  // Case when id % 15 == 0 (both % 3 and % 5)
                  ['==', ['%', ['get', 'id'], 11], 0], '#FF6F61', // Color 1 (e.g., Red)
                  // Case when id % 5 == 0
                  ['==', ['%', ['get', 'id'], 7], 0], '#FFA07A', // Color 2 (e.g., Light Coral)
                  // Case when id % 3 == 0
                  ['==', ['%', ['get', 'id'], 3], 0], '#FFD700', // Color 3 (e.g., Gold)
                  // Default color
                  '#ADFF2F' // Color 4 (e.g., GreenYellow)
                  ],
                  'fill-extrusion-height': [
                    'case',
                    ['==', ['get', 'id'], selectedBooth], // Full height for selected booth
                    4,
                    ['==', ['get', 'id'], hoveredBoothId], // Half height for hovered booth
                    2,
                    1, // Default height
                  ],
                 'fill-extrusion-base': 0,
                 'fill-extrusion-opacity': 1,
                }, 
              });
            
              
              map.addLayer({
                id: 'booth-borders',
                type: 'line',
                source: 'booths',
                layout: {},
                paint: {
                  'line-color': '#aaaaaa', // White color for the borders
                  'line-width': 1, // Adjust the width for the border thickness
                }
              });


            // Layer for the booth labels
            map.addLayer({
              id: 'booth-labels',
              type: 'symbol',
              source: 'booths',
              layout: {
                'text-field': ['get', 'Name'],  // Ensure this matches your GeoJSON properties
                'text-size': 12,
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 0.6],
                'text-anchor': 'center',
              },
              paint: {
                'text-color': '#000000',  // Use a distinct color like black for visibility
                'text-halo-color': '#FFFFFF',
                'text-halo-width': 2.5,
                'text-halo-blur': 0.5,
                'text-opacity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15, 0,  // Hidden at zoom level 15 and below
                  16, [
                    'case',
                    ['==', ['get', 'category'], 'gold'], 1, // Visible for gold category at zoom 16 and above
                    0
                  ],
                  17, 1  // Fully visible for all labels at zoom level 17 and above
                ]
              }
            });
            
            

              map.addLayer({
                id: 'passages-layer',
                type: 'fill',
                source: 'passages',
                paint: {
                  'fill-color': '#ffffff', // Orange color for passages
                  'fill-opacity': 0.01,
                },
              });

              // // Labels on stands
              // data.features.forEach((booth, index) => {
              //   if (index % 10 === 0) {
              //     const coordinates = booth.geometry.coordinates[0][0];
              //     const { Name } = booth.properties;

              //     const lngLat = Array.isArray(coordinates) && coordinates.length === 2 ? coordinates : coordinates[0];

              //     const el = document.createElement('div');
              //     el.className = 'booth-marker';
              //     el.textContent = Name;

              //     if (Array.isArray(lngLat)) {
              //       const marker = new mapboxgl.Marker(el)
              //         .setLngLat(lngLat)
              //         .addTo(map);

              //       markers.current.push(marker);
              //     }
              //   }
              // });

              map.on('click', 'booths-layer', (e) => {
                // const coordinates = e.features[0].geometry.coordinates[0][0].slice();
                // const { Name, Description, id } = e.features[0].properties;

                // flyToBooth(map, coordinates, id);
                handleView( e.features[0], map);
              });

              map.on('mouseenter', 'booths-layer', (e) => {
                const boothId = e.features[0].id;
                console.log( "mouseenter" + JSON.stringify(e.features[0]) );
                console.log( "mouseenter" + boothId );
                if( boothId !== undefined && boothId !== 0 )
                  setHoveredBoothId(boothId);
                map.getCanvas().style.cursor = 'pointer';
              });

              map.on('mouseleave', 'booths-layer', () => {
                setHoveredBoothId(null);
                map.getCanvas().style.cursor = '';
              });

              map.on('click', 'booths-layer', (e) => {
                const boothId = e.features[0].id;
                setSelectedBooth(boothId);
              });

              setMap(map);
            });
        });
      };

      if (!map) initializeMap({ setMap, mapContainer });
    }
  }, [map, selectedBooth, loading]);

  const flyToBooth = (map, coordinates, boothId) => {
    if (map) {
      map.flyTo({
        center: coordinates,
        essential: true,
        zoom: 18,
        pitch: 60,
        bearing: 123.2,
      });
    }
    setSelectedBooth(boothId);
  };

  const flyToCompany = (map, coordinates, boothId) => {
    if( boothId > 0 )
    {
      const coord = convertToLngLat(coordinates);
      if (map) {
        map.flyTo({
          center: coord[0],
          essential: true,
          zoom: 19.5,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 9.0,
        });
      }
      setSelectedBooth(boothId);
    }
  };


  const handleBoothSelection = (boothId) => {
    setSelectedBooth(boothId);
    map.flyTo({
      center: booths.find(booth => booth.id === boothId).geometry.coordinates[0][0],
      zoom: 23,
      pitch: 60,
      bearing: 123.2,
    });
  };

  const resetView = () => {
    if (map) {
      map.flyTo({
        center: initialViewState.center,
        zoom: initialViewState.zoom,
        pitch: initialViewState.pitch,
        bearing: initialViewState.bearing,
        essential: true, // This animation is considered essential with respect to prefers-reduced-motion
      });
    }
  };
  const menuItems = [
    { name: 'Search', icon: <MagnifyingGlassIcon className="w-5 h-5" /> },
    { name: 'Directions', icon: <MapPinIcon className="w-5 h-5" /> },
    { name: 'Bookmarks', icon: <BookmarkIcon className="w-5 h-5" /> },
    { name: 'Tasks', icon: <Bars3Icon className="w-5 h-5" /> },
    { name: 'Help', icon: <QuestionMarkCircleIcon className="w-5 h-5" /> },
  ];

  return (
    <div>
     <div style={{ position: 'relative', height: '100vh' }}>
      {loading ? (
        <SplashScreen />  // Render the splash screen while loading
      ) : (
        <div style={{ position: 'relative', height: '100vh' }}>
          { isDesktop && ( <DesktopSideBar menuItems={menuItems} handleMenuClick={handleMenuClick}  /> )}
          {/* Main Content */}
          <div ref={mapContainer} className="fixed z-5 top-0 left-0 h-full w-full" /> 
          <div className={` ${isMobile ? "left-4 h-[50%]" : "left-10  h-full" } z-2`} style={{pointerEvents: 'none'
            
          }}>
              {/* Set pointer-events to none so that this div doesn't block touch events */}
              {activeItem === 'Search' && !selectedCompany && (
                <div style={{ pointerEvents: 'auto' }}> {/* Allow interaction with specific components */}
                  <SearchBar booths={booths} setFilteredBooths={setFilteredBooths} setFilter={setFilter} isMobile={isMobile}/>
                </div>
              )}
              {!selectedCompany && 
                (activeItem === 'Search' ||  (activeItem === 'Tasks' && activeTask === 'Sectors' )) 
                && filteredBooths.length > 0 
                && filteredBooths.length < booths.length && (
                <div style={{ pointerEvents: 'auto' }}> {/* Allow interaction with specific components */}
                  <SearchResultsList results={filteredBooths} onView={handleView} map={map} toggleBookmark={toggleBookmark} bookmarkedBooths={bookmarkedBooths} isMobile={isMobile}/>
                </div>
              )}
              {activeItem === 'Get Directions' && !selectedCompany && (
                <div style={{ pointerEvents: 'auto' }}>
                  {/* Your Get Directions Component Here */}
                </div>
              )}
              {activeItem === 'Bookmarks' && !selectedCompany && (
                <div style={{ pointerEvents: 'auto',         height: '80%'  // Adjust this to 80%
                }}>
                  <FavoritesList favorites={bookmarkedBooths} onView={handleView} map={map} toggleBookmark={toggleBookmark} setBookmarksFilter={setBookmarksFilter} isMobile={isMobile}/>              
                </div>
              )}
              {selectedCompany && (
                <div style={{ pointerEvents: 'auto' }}>
                  <CompanyDetailsCard company={selectedCompany} onBack={handleBack} toggleBookmark={toggleBookmark} favorites={bookmarkedBooths}
                  isBookmarked={bookmarkedBooths.some(b => b.id === selectedCompany.id)}
                  isVisited={visitedBooths.includes(selectedCompany.id)}
                  toggleVisited={toggleVisited}/>
                </div>
              )}
              {activeItem === 'Tasks' && activeTask === 'Sectors' && isCategorySelectorVisible && (
                <div style={{ pointerEvents: 'auto' }}>
                <CategorySelector  isMobile={isMobile} categories={categories}  setFilter={setFilter} 
                   filteredBooths={filteredBooths}  onView={handleView} map={map} toggleBookmark={toggleBookmark} 
                   bookmarkedBooths={bookmarkedBooths} closeCategorySelector={closeCategorySelector} 
                 />
              </div>
            )}
              {activeItem === 'Tasks' && (
                <div style={{ pointerEvents: 'auto' }}>
                 <DesktopTasksBar isMobile={isMobile}  resetView={resetView}  setFilter={setFilter} setActiveTask={setActiveTask} activeTask={activeTask}  setCategorySelectorVisible={setIsCategorySelectorVisible} setIsAmenitiesVisible={setIsAmenitiesVisible} setIsSupportTaskBarVisible={setIsSupportTaskBarVisible} />
                </div>
              )}  
              {isAmenitiesVisible && (
                <AmenitiesTaskBar amenities={amenities} setActiveAmenity={setActiveAmenity} />
              )}
              {isSupportTaskBarVisible && (
                <SupportTaskBar supportCategories={supportCategories} setActiveSupport={setActiveSupport} />
              )}

            </div>
        </div>
      )}
    </div>
    <a href="https://xporience.com" target="blank">
  <div
    className={`flex items-start  px-1 py-1 rounded-lg fixed ${
      isMobile ? 'bottom-12 mr-1' : 'bottom-2 mr-12'
    } right-2 bg-gray-100/50 backdrop-blur text-xxs text-gray-800 whitespace-nowrap z-[2] sm:bottom-4 sm:right-4 sm:bg-gray-300/40`}
  >
    Event map by <h3 className="text-red-600"> &nbsp;Xporience </h3>
  </div>
</a>
    { isMobile && ( <MobileSideBar menuItems={menuItems} handleMenuClick={handleMenuClick}  /> )}
    </div>
 );

};

// ===================================================================================//
// ===================================================================================//
const FavoritesList = ({ favorites, onView, map, toggleBookmark, isMobile, setBookmarksFilter }) => {
  useEffect(() => {
    // Automatically highlight the bookmarked booths when the list is displayed
    setBookmarksFilter();
  }, [favorites]);

  return (
    <div className={`fixed top-8 ${isMobile ? "left-4 h-[80%]" : "top-14 left-16 h-[80%]"} bg-white bg-opacity-80 shadow rounded-lg p-3 overflow-y-auto z-20 w-80 flex flex-col custom-scrollbar`}>
      <h2 className="text-base font-bold mb-4">My Favorites</h2>
      {favorites.length > 0 ? (
        <div className="flex-grow">
          {favorites.map((booth, index) => (
            <FavoriteCard key={index} onView={onView} booth={booth} map={map} toggleBookmark={toggleBookmark} isBookmarked={true} />
          ))}
        </div>
      ) : (
        <div className="flex-grow flex flex-col items-center justify-center text-gray-600">
          <BookmarkIcon className="w-12 h-12 mb-4 text-red-500" />
          <p className="text-center">No favorites added yet.</p>
          <p className="text-center">Click the bookmark icon on a booth to add it to your favorites.</p>
        </div>
      )}
    </div>
  );
};


// ===================================================================================//
// ===================================================================================//
const FavoriteCard = ({ booth, map, onView, toggleBookmark, isBookmarked }) => {
  return (
    <div className="flex items-center bg-white rounded-lg shadow-md p-2 pb-4 mb-4" >
      {/* Logo Section */}
      <div className="w-1/3 flex justify-center items-center">
        <img src={booth.properties.image} alt={`${booth.properties.Name} Logo`} className="h-16 w-16 object-contain" />
      </div>

      {/* Info Section */}
      <div className="w-2/3 pl-4">
        <h3 className="text-base font-semibold pb-1">{booth.properties.Name}</h3>
        <p className="text-xs text-gray-600 overflow-hidden overflow-ellipsis whitespace-nowrap">
          {booth.properties.Description}
        </p>
        
        {/* Buttons Section */}
        <div className="flex items-center mt-2">
          <button className="flex items-center text-red-500 border border-red-500 px-3 py-1 rounded-md mr-2 hover:bg-red-500 hover:text-white"
            onClick={() => onView(booth, map)}
              >
            <EyeIcon className="w-4 h-4 mr-1" />
            View
          </button>
          <button className="flex items-center ${isBookmarked ? 'text-white bg-red-500' : 'text-red-500 border border-red-500'}  hover:text-red-500" 
          style={{
            marginLeft: '0.8rem', // Move the bar to the right beyond the sidebar            
          }}
          onClick={() => toggleBookmark(booth)}

          >
          {isBookmarked ? (
              <BookmarkIconSolid className="w-6 h-6 text-red-500" />
            ) : (
              <BookmarkIcon className="w-6 h-6 text-gray-500" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

// ===================================================================================//
// ===================================================================================//
const DesktopSideBar = ( { activeItem, menuItems, handleMenuClick } ) =>
{
return ( 
  <div className='fixed top-0 left-0 h-full w-12 bg-red z-10'>
  <div className={`flex flex-col bg-slate-50 text-white h-full w-12 transition-width duration-300`}>
    <nav className="flex flex-col mt-10 space-y-2">
      {menuItems.map((item) => (
        <button
          key={item.name}
          className={`flex items-center px-4 py-2 text-sm font-medium ${activeItem === item.name ? 'text-red-500' : 'text-black'} hover:text-red-500 focus:outline-none`}
          onClick={() => handleMenuClick(item.name)}
        >
          <span>{item.icon}</span>
        </button>
      ))}
    </nav>
  </div>
</div>
  );
};

// ===================================================================================//
// ===================================================================================//
const MobileSideBar = ( { menuItems,activeItem, handleMenuClick } ) =>
  {
  return ( 
    <div
  className={`fixed bottom-0 w-full bg-white bg-opacity-40 z-30 shadow rounded-lg`}
  style={{
    padding: '0.5rem',
  }}
>
  <div className={`flex flex-row bg-slate-50 text-black w-full transition-width duration-300`}>
    <nav className="flex flex-row justify-center space-x-2 w-full">
      {menuItems.map((item) => (
        <button
          key={item.name}
          className={`flex items-center px-4 py-2 text-sm font-medium ${activeItem === item.name ? 'text-red-500' : 'text-black'} hover:text-red-500 focus:outline-none`}
          onClick={() => handleMenuClick(item.name)}
        >
          <span>{item.icon}</span>
        </button>
      ))}
    </nav>
  </div>
</div>

    );
  };


// ===================================================================================//
// ===================================================================================//
  const AmenitiesTaskBar = ({ amenities, setActiveAmenity }) => {
    const amenitiesBarRef = useRef(null);
  
    const scrollAmenitiesBar = (direction) => {
      if (amenitiesBarRef.current) {
        const scrollAmount = 100; 
        if (direction === 'left') {
          amenitiesBarRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        } else if (direction === 'right') {
          amenitiesBarRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
      }
    };
  
    return (
      <div
        className="fixed left-16 top-[calc(5rem)] z-10 flex space-x-2 overflow-x-auto scrollbar-hide px-1 inset-x-0 z-[2] max-w-xl"
      >
        {/* Left Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar('left')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
          </svg>
        </div>
  
        {/* Amenities Buttons */}
        <div
          className="flex space-x-2 overflow-x-auto scrollbar-hide"
          ref={amenitiesBarRef}
          style={{ maxWidth: '100%', whiteSpace: 'nowrap' }}
        >
          {amenities.map((amenity, index) => (
            <button
              key={index}
              onClick={() => setActiveAmenity(amenity)}
              className={`inline-flex px-2 py-1 rounded-lg text-sm leading-5 items-center whitespace-nowrap font-medium bg-gray-100 text-gray-500 border border-gray-300 hover:bg-gray-200`}
            >
              {amenity}
            </button>
          ))}
        </div>
  
        {/* Right Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar('right')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
          </svg>
        </div>
      </div>
    );
  };

  const SupportTaskBar = ({ supportCategories, setActivesupport }) => {
    const amenitiesBarRef = useRef(null);
  
    const scrollAmenitiesBar = (direction) => {
      if (amenitiesBarRef.current) {
        const scrollAmount = 100; 
        if (direction === 'left') {
          amenitiesBarRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        } else if (direction === 'right') {
          amenitiesBarRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
      }
    };
  
    return (
      <div
        className="fixed left-16 top-[calc(5rem)] z-10 flex space-x-2 overflow-x-auto scrollbar-hide px-1 inset-x-0 z-[2] max-w-xl"
      >
        {/* Left Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar('left')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
          </svg>
        </div>
  
        {/* Amenities Buttons */}
        <div
          className="flex space-x-2 overflow-x-auto scrollbar-hide"
          ref={amenitiesBarRef}
          style={{ maxWidth: '100%', whiteSpace: 'nowrap' }}
        >
          {supportCategories.map((amenity, index) => (
            <button
              key={index}
              onClick={() => setActivesupport(amenity)}
              className={`inline-flex px-2 py-1 rounded-lg text-sm leading-5 items-center whitespace-nowrap font-medium bg-gray-100 text-gray-500 border border-gray-300 hover:bg-gray-200`}
            >
              {amenity}
            </button>
          ))}
        </div>
  
        {/* Right Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar('right')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
          </svg>
        </div>
      </div>
    );
  };
  

// ===================================================================================//
// ===================================================================================//
const DesktopTasksBar = ( {isMobile, resetView, setActiveTask, activeTask, setFilter, setCategorySelectorVisible, setIsAmenitiesVisible, setIsSupportTaskBarVisible} ) => {

  // Tasks array in the specific order mentioned
  const tasks = [
    'View All',
    'Sectors',
    'Getting Here',
    'Support',
    'Innovation Awards',
    'Amenities'
  ];
  useEffect(() => {
    if( activeTask === "View All" )
    {
      setCategorySelectorVisible(false);  
      setIsSupportTaskBarVisible(false);
      setFilter([]);
      resetView();
    }
  }, [activeTask]);


  const handleTaskClick = (task) => {
    setActiveTask(task);
    setIsSupportTaskBarVisible(false);  // Show Amenities Task Bar
    if (task === 'Sectors') {
      setCategorySelectorVisible(true);
    } else if (task === 'Amenities') {
      setIsAmenitiesVisible(true);  // Show Amenities Task Bar
    } else if (task === 'Support') {
      setIsSupportTaskBarVisible(true);  // Show Amenities Task Bar
    } else {
      setIsAmenitiesVisible(false); // Hide when another task is selected
    }
  };
  
  const taskBarRef = useRef(null);

  const scrollTaskBar = (direction) => {
    if (taskBarRef.current) {
      const scrollAmount = 100; // Adjust scroll amount as needed
      if (direction === 'left') {
        taskBarRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      } else if (direction === 'right') {
        taskBarRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  return (
      <div
        className={`fixed ${isMobile ? "bottom-20" : "top-4 sm:left-16"}  left-1 z-10 flex space-x-2 overflow-x-auto scrollbar-hide px-1 sm:px-0 inset-x-0 z-[2] max-w-xl `}
        style={{
        }}
      >
      {/* Left Scroll Button */}
      <div
        className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
        onClick={() => scrollTaskBar('left')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          aria-hidden="true"
          className="w-5 h-5 heroicon-stroke-semibold"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
        </svg>
      </div>
      {/* Task Buttons */}
      <div
        className="flex space-x-2 overflow-x-auto scrollbar-hide"
        ref={taskBarRef}
        style={{
          maxWidth: '100%',
          whiteSpace: 'nowrap',
        }}
      >
    {tasks.map((task, index) => (
        <button
          key={index}
          onClick={() => handleTaskClick(task)}
          className={`inline-flex px-2 py-1 rounded-lg mr-2 text-sm leading-5 items-center  whitespace-nowrap  font-medium ${task === activeTask ? 'bg-gradient-to-r from-[#d93953] to-[#f48432] text-white' : 'bg-gray-100 text-gray-500 border border-gray-300 hover:bg-gray-200'}`}
          style={{
            height: '100%', // Reduce the height by 15%
            marginLeft: '0.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem'
      }}
        >
          {task.replace(/"/g, '')} {/* Display without quotes */}
        </button>
      ))}
      </div>
     {/* Right Scroll Button */}
     <div
        className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
        onClick={() => scrollTaskBar('right')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          aria-hidden="true"
          className="w-5 h-5 heroicon-stroke-semibold"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
        </svg>
      </div>
    </div>
  );
};

// ===================================================================================//
// ===================================================================================//
const CompanyDetailsCard = ({
  company,
  onBack,
  toggleBookmark,
  isBookmarked,
  isVisited,
  toggleVisited,
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  // Reset showFullDescription when the company prop changes
  useEffect(() => {
    setShowFullDescription(false);
  }, [company]);

  const handleShowFullDescription = () => {
    setShowFullDescription(true);
  };

  const description = company.properties.Description ? company.properties.Description  : "";
  const truncatedDescription =
    description.length > 64 ? description.substring(0, 64) + '...' : description;

  const handleShare = () => {
    const siteName = window.location.hostname;
    const url = `https://${siteName}/company`;
    const title = company.properties.Name;

    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: url,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      // Fallback if Web Share API is not supported
      alert(`Share this link: ${url}`);
    }
  };

  return (
    <div
      className="absolute top-8 h-[80%] bg-white bg-opacity-90 rounded-lg p-4 z-10 w-80 flex flex-col"
      style={{
        marginLeft: 'calc(5rem)', // Move the card to the right beyond the sidebar
      }}
    >
      {/* Header Section */}
      <div className="flex items-center justify-between flex-none">
        <button onClick={onBack} className="text-red-600">
          <ArrowLeftIcon className="w-4 h-4" />
        </button>
        <h2 className="text-xl font-semibold mb-4">Company Details</h2>
      </div>

      {/* Bookmark and Share Buttons */}
      <div className="flex justify-end space-x-2 mb-4 flex-none">
        <button
          className="p-2 bg-gray-200 rounded-full"
          onClick={() => toggleBookmark(company)}
        >
          {isBookmarked ? (
            <BookmarkIconSolid className="w-6 h-6 text-red-500" />
          ) : (
            <BookmarkIcon className="w-6 h-6 text-gray-500" />
          )}
        </button>
        <button className="p-2 bg-gray-200 rounded-full" onClick={handleShare}>
          <ShareIcon className="w-5 h-5 text-gray-600" />
        </button>
      </div>

      {/* Scrollable Content */}
      <div className="overflow-y-auto flex-grow pr-4 -mr-4">
        <img
          src={company.properties.image}
          alt={`${company.properties.Name} Logo`}
          className="h-32 object-contain mb-4 mx-auto"
        />
        <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
          {company.properties.Name}{' '}
          <span className="text-sm text-red-500">
            {company.properties.sponsorType}
          </span>
        </h3>
        <p className="text-gray-400 mt-1 flex items-center">
          <MapPinIcon className="w-4 h-4 mr-1" />
          Booth {company.properties.boothDetails}
        </p>

        {/* Centered and Wider Buttons */}
        <button className="w-3/5 mx-auto flex items-center justify-center text-white bg-black px-3 py-2 rounded-full my-2">
          <MapPinIcon className="w-4 h-4 mr-1" />
          Direction
        </button>
        <button
          className={`w-3/5 mx-auto flex items-center justify-center px-3 py-2 rounded-full my-2 ${
            isVisited
              ? 'text-white bg-red-500'
              : 'text-red-500 border border-red-500'
          }`}
          onClick={() => toggleVisited(company.id)}
        >
          Mark as visited
        </button>

        <p className="text-sm text-gray-600 my-4">
          {showFullDescription ? description : truncatedDescription}
        </p>
        {!showFullDescription && (
          <button
            className="text-xs text-blue-500"
            onClick={handleShowFullDescription}
          >
            + Show full Description
          </button>
        )}
        <div className="flex space-x-2 mt-4">
          <a
            href={
              company.properties.website ? company.properties.website.startsWith('http')
                ? company.properties.website
                : `https://${company.properties.website}`
                : "" } 
            target="_blank"
            rel="noopener noreferrer"
          >
            <GlobeAltIcon className="w-4 h-4 mr-1" />
          </a>
          <a
            href={
              company.properties.linkedin ? company.properties.linkedin.startsWith('http')
                ? company.properties.linkedin
                : `https://${company.properties.linkedin}`
            : "" }
            target="_blank"
            rel="noopener noreferrer"
          >
            <GlobeAltIcon className="w-4 h-4 mr-1" />
          </a>
        </div>
      </div>
    </div>
  );
};




// ===================================================================================//
// ===================================================================================//

const SearchBar = ({ booths, setFilteredBooths, isMobile, setFilter }) => {
  const [query, setQuery] = useState('');
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (query) {
      const matchingFeatures = booths.filter((feature) =>
        feature.properties?.Name && feature.properties?.Name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredBooths(matchingFeatures);
      setFilter(matchingFeatures);
    } else {
      setFilteredBooths([]);
      setFilter([]);
      
    }
  }, [query, booths, setFilteredBooths]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    // Here, 3rem is approximately the width of the sidebar, and 1rem is added as a gap between the sidebar and the search bar.
    <div className="fixed top-4" style={{ marginLeft: `${ isMobile ? "" : "4rem"}` }}> {/* Adjust the margin-left to move  it beyond the sidebar */}
     <div className="relative w-64 md:w-120"> {/* Increase width by 25% */}
    <MagnifyingGlassIcon className="absolute left-3 top-2.5 w-5 h-5 text-gray-500" /> {/* Add search icon */}
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={handleInputChange}
        className="pl-10 w-full px-4 py-2 text-sm border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-red-300"
        />

     {/* Dropdown List */}
     {showResults && (
        <div className="absolute left-0 w-full bg-white shadow-lg rounded-lg mt-2 max-h-60 overflow-y-auto">
          <div className="p-2 border-b border-gray-200">
            <h4 className="text-xs font-semibold text-gray-500">PLACES</h4>
            {booths.filter((booth) =>
              booth.properties?.Name.toLowerCase().includes(query.toLowerCase())
            ).map((booth, index) => (
              <div key={index} className="py-2 px-4 hover:bg-gray-100 cursor-pointer">
                {booth.properties.Name}
              </div>
            ))}
          </div>
          <div className="p-2">
            <h4 className="text-xs font-semibold text-gray-500">CATEGORIES</h4>
            {/* Assuming you have categories to show here */}
            <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Example Category 1</div>
            <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Example Category 2</div>
          </div>
        </div>
      )}
     </div>
    </div>
  );
};

// ===================================================================================//

// ===================================================================================//
const SearchResultsList = ({ results, onView, map, toggleBookmark, bookmarkedBooths,isMobile }) => {
  return (
    <div className={`fixed top-16  bg-white bg-opacity-80 shadow rounded-lg p-3 overflow-y-auto z-20 w-80 flex flex-col justify-start  custom-scrollbar`}
    style={{
      marginLeft:`${ isMobile ? "" : "4rem"}`, // Move the bar to the right beyond the sidebar
      height: '80%'
    }}
    >
      {/* <h2 className="text-xl font-semibold mb-4">Search Results</h2> */}
      {results.length > 0 && results.map ? (
        results.map((booth, index) => (
          <FavoriteCard key={index} booth={booth} map={map} onView={onView} toggleBookmark={toggleBookmark}
          isBookmarked={bookmarkedBooths.some(b => b.id === booth.id)} />
        ))
      ) : (
        <div className="flex-grow flex flex-col items-center justify-center text-gray-600">
          <p className="text-center">No results found.</p>
        </div>
      )}
    </div>
  );
};

// ===================================================================================//
// ===================================================================================//
const CategorySelector = ({ categories, setFilter, filteredBooths, onView, map, toggleBookmark, bookmarkedBooths, closeCategorySelector,isMobile }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Find the "Sectors" category
  const sectorsCategory = categories.find(
    (category) => category.name === "Sectors"
  );

  const handleFilterClick = () => {
    setFilter(selectedCategories);
    closeCategorySelector();
  };

  const handleCategoryClick = (subcategorySlug) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(subcategorySlug)
        ? prevSelected.filter((slug) => slug !== subcategorySlug)
        : [...prevSelected, subcategorySlug]
    );
  };

  const handleSelectAll = (group) => {
    const groupSlugs = group.children.map((subCat) => subCat.slug);
    setSelectedCategories((prevSelected) => {
      const areAllSelected = groupSlugs.every((slug) => prevSelected.includes(slug));
      return areAllSelected
        ? prevSelected.filter((slug) => !groupSlugs.includes(slug))
        : [...prevSelected, ...groupSlugs.filter((slug) => !prevSelected.includes(slug))];
    });
  };

  

  return (
    <div className="bg-red-900 absolute top-0 left-0 bg-opacity-80  w-full h-full">
    <div
      className="category-selector absolute top-16 left-1  bg-white bg-opacity-1 rounded-lg pl-4 z-10 size-full flex flex-col"
      style={{
        marginLeft: `calc((${!isMobile ? 1 : 0} + 0.1) * (3rem + 1rem))`, // Move the bar to the right beyond the sidebar
        height: "80%",
        width: "90%"
      }}
    >
      <div className="text-lg font-semibold leading-6 text-gray-900 border-b-2 mb-2 pt-2 pb-2 border-red-200">
        Select Categories
      </div>
      <div
        className="pl-2 pr-1 custom-scrollbar"
        style={{
          overflowY: "auto",
          paddingTop: "1rem",
        }}
      >
        {sectorsCategory && sectorsCategory.children.map((group) => (
          <div key={group.id} className="group mb-4 border-b-2 pb-6 border-gray-200">
            <div className="group-header flex justify-between items-center mb-2">
              <span className="text-md font-semibold">{group.name}</span>
              <button
                className="text-sm font-semibold border-b-2 border-gray-200 text-gray-200 hover:text-gray-700"
                onClick={() => handleSelectAll(group)}
              >
                select all
              </button>
            </div>
            <div className="subcategories text-sm flex flex-wrap gap-2">
              {group.children.map((subCategory) => (
                <button
                  key={subCategory.id}
                  className={`px-3 py-1 rounded-lg border border-gray-300 text-left text-xs ${
                    selectedCategories.includes(subCategory.slug)
                      ? "bg-gradient-to-r from-[#d93953] to-[#f48432] text-white"
                      : "bg-gray-50 text-gray-700 hover:bg-gray-200"
                  }`}
                  onClick={() => handleCategoryClick(subCategory.slug)}
                >
                  {subCategory.name}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full pb-2 border-t-2 flex items-center justify-center px-4 text-sm">
        <button
          className="w-full px-3 py-2 bg-gray-700 text-white rounded-md text-center font-semibold mt-4"
          onClick={handleFilterClick}
        >
          Filter Exhibitors
        </button>
      </div>
    </div>
  </div>
  );
};

const AmenitiesSelector = ({ categories, setFilter, filteredBooths, onView, map, toggleBookmark, bookmarkedBooths, closeCategorySelector }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Find the "Sectors" category
  const sectorsCategory = categories.find(
    (category) => category.name === "Support"
  );

  const handleFilterClick = () => {
    setFilter(selectedCategories);
    closeCategorySelector();
  };

  const handleCategoryClick = (subcategorySlug) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(subcategorySlug)
        ? prevSelected.filter((slug) => slug !== subcategorySlug)
        : [...prevSelected, subcategorySlug]
    );
  };

  const handleSelectAll = (group) => {
    const groupSlugs = group.children.map((subCat) => subCat.slug);
    setSelectedCategories((prevSelected) => {
      const areAllSelected = groupSlugs.every((slug) => prevSelected.includes(slug));
      return areAllSelected
        ? prevSelected.filter((slug) => !groupSlugs.includes(slug))
        : [...prevSelected, ...groupSlugs.filter((slug) => !prevSelected.includes(slug))];
    });
  };

  

  return (
    <div
      className="category-selector absolute top-16 left-[calc(5rem + 1rem)] h-full bg-white bg-opacity-80 rounded-lg p-4 z-10 size-full flex flex-col"
      style={{
        marginLeft: "calc(3rem + 1rem)", // Move the bar to the right beyond the sidebar
        height: "80%",
      }}
    >
      <div className="text-lg font-semibold leading-6 text-gray-900 border-b-2 mb-2 pb-2 border-red-200">
        Select Categories
      </div>
      <div
        className="pl-6 pr-8 custom-scrollbar"
        style={{
          overflowY: "auto",
          paddingTop: "1rem",
        }}
      >
        {sectorsCategory && sectorsCategory.children.map((group) => (
          <div key={group.id} className="group mb-4 border-b-2 pb-6 border-gray-200">
            <div className="group-header flex justify-between items-center mb-2">
              <span className="text-md font-semibold">{group.name}</span>
              <button
                className="text-sm font-semibold border-b-2 border-gray-200 text-gray-500 hover:text-gray-700"
                onClick={() => handleSelectAll(group)}
              >
                select all
              </button>
            </div>
            <div className="subcategories text-sm flex flex-wrap gap-2">
              {group.children.map((subCategory) => (
                <button
                  key={subCategory.id}
                  className={`px-3 py-1 rounded-lg border border-gray-300 text-xs ${
                    selectedCategories.includes(subCategory.slug)
                      ? "bg-gradient-to-r from-[#d93953] to-[#f48432] text-white"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                  onClick={() => handleCategoryClick(subCategory.slug)}
                >
                  {subCategory.name}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full border-t-2 flex items-center justify-center px-4 text-sm">
        <button
          className="w-1/2 px-3 py-2 bg-gray-700 text-white rounded-md text-center font-semibold mt-4"
          onClick={handleFilterClick}
        >
          Filter Exhibitors
        </button>
      </div>
    </div>
  );
};

const SupportSelector = ({ categories, setFilter, filteredBooths, onView, map, toggleBookmark, bookmarkedBooths, closeCategorySelector }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Find the "Sectors" category
  const sectorsCategory = categories.find(
    (category) => category.name === "Support"
  );

  const handleFilterClick = () => {
    setFilter(selectedCategories);
    closeCategorySelector();
  };

  const handleCategoryClick = (subcategorySlug) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(subcategorySlug)
        ? prevSelected.filter((slug) => slug !== subcategorySlug)
        : [...prevSelected, subcategorySlug]
    );
  };

  const handleSelectAll = (group) => {
    const groupSlugs = group.children.map((subCat) => subCat.slug);
    setSelectedCategories((prevSelected) => {
      const areAllSelected = groupSlugs.every((slug) => prevSelected.includes(slug));
      return areAllSelected
        ? prevSelected.filter((slug) => !groupSlugs.includes(slug))
        : [...prevSelected, ...groupSlugs.filter((slug) => !prevSelected.includes(slug))];
    });
  };

  

  return (
    <div
      className="category-selector absolute top-16 left-[calc(5rem + 1rem)] h-full bg-white bg-opacity-80 rounded-lg p-4 z-10 size-full flex flex-col"
      style={{
        marginLeft: "calc(3rem + 1rem)", // Move the bar to the right beyond the sidebar
        height: "80%",
      }}
    >
      <div className="text-lg font-semibold leading-6 text-gray-900 border-b-2 mb-2 pb-2 border-red-200">
        Select Categories
      </div>
      <div
        className="pl-6 pr-8 custom-scrollbar"
        style={{
          overflowY: "auto",
          paddingTop: "1rem",
        }}
      >
        {sectorsCategory && sectorsCategory.children.map((group) => (
          <div key={group.id} className="group mb-4 border-b-2 pb-6 border-gray-200">
            <div className="group-header flex justify-between items-center mb-2">
              <span className="text-md font-semibold">{group.name}</span>
              <button
                className="text-sm font-semibold border-b-2 border-gray-200 text-gray-500 hover:text-gray-700"
                onClick={() => handleSelectAll(group)}
              >
                select all
              </button>
            </div>
            <div className="subcategories text-sm flex flex-wrap gap-2">
              {group.children.map((subCategory) => (
                <button
                  key={subCategory.id}
                  className={`px-3 py-1 rounded-lg border border-gray-300 text-xs ${
                    selectedCategories.includes(subCategory.slug)
                      ? "bg-gradient-to-r from-[#d93953] to-[#f48432] text-white"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                  onClick={() => handleCategoryClick(subCategory.slug)}
                >
                  {subCategory.name}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full border-t-2 flex items-center justify-center px-4 text-sm">
        <button
          className="w-1/2 px-3 py-2 bg-gray-700 text-white rounded-md text-center font-semibold mt-4"
          onClick={handleFilterClick}
        >
          Filter Exhibitors
        </button>
      </div>
    </div>
  );
};

export default App;
